module.exports = {
  technologies: [
    'React',
    'Express',
    'Node.js',
    'MongoDB',
    'Third-Party APIs',
    'MERN Stack',
    'Handlebars.js',
    'MVC',
    'RESTful APIs',
    'Functional Programming',
    'OOP - Object Oriented Programming',
    'MYSQL',
    'PWA - Progressive Web Apps',
  ],
  languages: ['Lua', 'Visual Basic for Applications - vba', 'Visual Basic Scripts - vbs', 'JavaScript - js', 'TypeScript - ts', 'GDScript'],
};

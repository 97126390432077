module.exports = {
  aboutSummary: [
    `Over the past 14 years, I've delved in and out of programming with a goal of simplifying and creating something. It's something that's always brought fulfillment to my life and I'm ready to take the step and turn my hobby into a career. I look forward to solving more problems and utilizing my knowledge for something productive.`,
    `From my time in the VEX robotics program to automating tasks at work to my more recent venture into game development, I enjoy seeing my skills grow to be able to conquer tougher problems.`,
  ],
  story: [
    `My journey in coding started in high school when I was part of my school's robotics team. We built and programmed robots from scratch to compete in games against teams from different schools. I competed for 3 out of the 4 years in high school and all 3 years, we ended up making it to the world championship. One of the years, we ranked second in our division and took first place in our state competition. Another year, we received an award for the world’s most creative robot! I was mainly a builder and one of the primary drivers, but I assisted in writing code for both accepting controls from the remote as well as a full autonomous mode at the beginning of each match. The coding started as a visual based programming application using puzzle-like pieces to compile logic. This was a great introduction to logical thinking but was quickly outclassed by writing the code by hand. The part I most enjoyed about this was using both timing and sensor inputs during the autonomous portion to achieve our goals.`,
    `While still in high school, I began playing Minecraft and got pulled into the modding scene. I never wrote a mod but one of them that I played was called computer craft (CC). In CC, you could operate computers that would allow you to input custom code (written in Lua) and automate tasks in the game. There were some sensor inputs and monitors that would allow you to manipulate things like redstone signals but the part I liked the most were turtles. Turtles were computers that could move on their own. They required fuel to be able to do various movements and actions, so the first step was automating fuel production to be able to automate other tasks like mining and crafting (pun intended). I had no idea at the time, but this was the true spark for my coding journey.`,
    `Over the next few years, I would struggle with my passion for coding. I had realized that I loved writing code so much, but I had no idea how to use it. I gradually began to realize that I just couldn’t find a use for it. My life wasn’t based around a computer at this point, so I didn’t have any reason or purpose to use and build my programming skills. I just couldn't figure out what to do with programming. What benefit could I have from it. I actually thought that it would remain a dream forever and I just wasn’t skilled enough to do anything with it.`,
    `Fast forward a few years of being in the Marine corps where my coding never left some basic automation in Minecraft. I was a fiscal clerk for our Battalion (I maintained and tracked our operational budget) and spent my days in reports and excel. So much of my day was filled with waiting on reports to pull, looking through reports, formatting reports, reading reports, working reports and all things revolving around these reports. My primary focus was on a report that overviewed our purchases. I took this report and formatted it in Excel to make it more readable. After that, I would compare it to our projected budget breakdown and get an up-to-date budget utilization broken down by company. This way, when someone would come in with a purchase request, I was able to approve or deny it before sending it for final approval. This process took me a couple of hours after I got familiar with Excel and what needed to happen. After doing this for a while, I was given a spreadsheet that had some interesting properties. You could select another spreadsheet from it, and it would automatically take it apart and provide you with exactly the information that you need. I was in awe to say the least. I immediately began researching how someone was able to achieve this. In walks Excel Macros.`,
    `After learning about macros (not quite realizing it was coding at this point), I discovered the ability to record your actions and reproduce them later with the click of a button. I was able to get to a point with recording my actions that I could shave off about 20-30 minutes of formatting my primary report and have much easier access to the breakdowns. This, of course, wasn’t good enough for me. Being intelligent and lazy has its perks. I began to develop the mindset of “Why would I do something that I can make the computer do for me?”. The more I recorded my actions, the more I noticed patterns in the code that it wrote for me. Because of this, I was able to delve into the code, find inefficiencies and remove them. I was able to fix my mistakes easier this way as well. Simply change the lines that the mistakes are on and keep moving forward. It got to the point that I would record the outline of what I was doing and write everything else from there. Again, why would I do the work that the recorder could do for me. I got to the point where I was doing things that the macro recorder was unable to do and that’s when it really took off.`,
    ` I was able to turn my multi-hour process of working reports into copy, paste and click this button. About 20 seconds later, there was the final result. I got so familiar with the report while doing this that I was able to get even more information than we were getting previously and more accurately. Since this process became so quick, I began doing it every day. Not only did we receive a daily update on our budget utilization, I also had so much more time on my hands to take on more tasks (and go on to automate those as well). The sergeant in charge of me recognized what I had done and began the process of nominating me for a NAM (Navy/Marine Corps Accommodation Medal). I had finally found a purpose for coding and couldn’t be happier. At this point, my limitation was Excel, but I was happy to finally be coding again. It wasn’t until a few years later when I was able to branch out of Excel and grow my skills as a true developer.`,
    `After finishing my contract with the Marine Corps, I went to school to become an Automotive technician. During my time at this trade school, I met someone that referred me to work with him for the leasing department a major semi-truck dealership. I got the job and began turning wrenches, but it wasn’t too long before I became skilled in our shop system and parts lookup software. After about a year, an opportunity opened to do some work in the office and spend much more time with our software. This piece of software is not exactly user friendly. Everything is set up to be functional but so many specific steps are required, and the slightest forgotten step can derail whatever you’re working on. I picked up on the software rather quick as I’m not what you would call the average user. One fateful day of messing around in the settings, I noticed something. “Script Recording and Playback”. My eyes flew wide open as I realized what I stumbled across. I tested a couple things out to confirm my theory.`,
    `This was Excel Macros all over again! I was able to do the exact thing that I did with Excel, but with our shop software. I recorded what I needed and wrote what I could. This piece of software uses vbscript to run automation tasks. It was fairly difficult to learn vbscript due to its… quirks, but I was just excited to finally be coding again. I would stay hours late some days writing code to automate some task and not even realize how much time I had spent on it. I wrote scripts for most of my tasks and was able to take on more and more because of it. Everything I wrote was pretty simple up to this point. I would just have the computer click a few buttons for me and save me between seconds and minutes each time. I had one task that I absolutely hated doing. It was so repetitive and required very little thought to do. The problem with it was the fact that it took between 15 minutes and an hour of mindless work. Copy, paste, copy, paste, pull out calculator to find markup, copy paste and so on. I now had a mission that became my turning point. You see, I’ve had a few high points in my journey this far, but this would be different. After accomplishing this, I would be able to break out of my shell of “record and tweak” to being able to think outside of the box. `,
    `To briefly explain what this task is, the main dealership would do work on our trucks and we need to send the bill to our customer. Our shop system isn’t equipped to route the invoice through our finances so we need to manually write an order with all parts and labor, including the cost and markup of parts/labor. This consists of going to the invoice that we received from the dealership and copy/pasting everything over. After that, pull out the calculator and find the price we’re charging the customer. This process took about 15 minutes for a small invoice and over an hour for a large one. We get these between 5 and 25 times a month depending on the work that was done on our behalf. As you can tell, the time adds up fast. What can I do about this?`,
    `“Why would I do something that I can make the computer do for me?” I put my programmer brain to the test and figured out how I can rid myself of this task for good. I learned a lot during this process, mostly debugging (if you know, you know) but also design and optimization. I would get so invested in solving this problem. After writing a few lines, I’d run it through and see what the result is. Success! More progress has been made. Let’s try this now. *Slaps desk* “Why didn’t that work!?”. “Wait, what if I try this?”. After close to 100 hours of developing, I finally had something that worked. Pending some more testing for edge cases, I did it. I turned 15-90 minutes of work down to 1-5 minutes. 10 minutes for a very large invoice on a day that our system was running slow. The best part was that I can do other things while this runs in the background. I never got the chance to do so because I only had enough time to prep the next invoice. Skip forward to more testing and another 100 hours of editing and I have a product that would handle over 95% of our rebills.`,
    `After this, I saw my potential shine through. I was able to find out that vbscript runs in windows, not our system. This means that I could use it to do things like pull reports in our system, generate Excel spreadsheets, send dynamically generated emails based on the contents of the reports and attach the spreadsheets to those emails. I was enjoying myself and I wanted more. I started doing some research on becoming a better programmer. This brought me to begin learning Java. I got a pretty good understanding of object-oriented programming and how to read/write some algorithms but I fell into the trap of “What good does this do me? What can I do with it?”. I started learning how to build a user interface (UI) in Java and my learning halted. I was going about doing it without tools to make it easier and it slowed me down so much. After struggling for a while, I decided to search for a way to continue progression instead of staying stagnant.`,
    `I ended up finding out that Javascript uses web browsers for the UI and it was so easy to get started with HTML and CSS. This was great because I could further my learning without it being halted by the UI. After spending some time with HTML, CSS and Javascript, I decided to enroll in DU’s coding bootcamp. Most of the technologies I know came from my experience in this bootcamp. We grew from basic HTML and CSS to MVC using Node, Express and Handlebars all the way to using the MERN stack to create a full stack application. I’m excited to see where my journey takes me next.`,
  ],
};

import React from 'react';
import './index.css';

export default function WelcomeCard() {
  return (
    <h1>
      I'm
      <br />
      <span>Joshua Tuinder</span>
      <br />
      Full Stack Developer
    </h1>
  );
}
